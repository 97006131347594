import React, { useCallback, useContext, useEffect, useState } from "react";
import { MainContext } from "../../App";
import Notification from "./notifications";
import toast from "react-hot-toast";

const EventNotifications = () => {
  const CTX = useContext(MainContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const getInfo = useCallback(async () => {
    try {
      const fetching = await fetch(`${CTX.url}user/action/view-info`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
      });
      const jsoned = await fetching.json();
      CTX.setToken(jsoned.data);
      CTX.setUser(jsoned.user)
    } catch (error) {
      console.log("error log from eachProduct getInfo ==>> ", error);
    }
    }, []);



    const getUserNotification = useCallback(async () => {
    try {
      const fetching = await fetch(
        `${CTX.url}user/action/notification/${CTX?.user?._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${CTX.token}`,
          },
        }
      );
      const jsoned = await fetching.json();
      setData(jsoned?.data);
    } catch (error) {
      console.log("error from getNotification ==> ", error);
    }
    }, []);


  const getPending = useCallback(async () => {
    try {
      const request = await fetch(`${CTX.url}user/transaction/pending`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${CTX.token}`,
        },
      });
      const parseJson = await request.json();
      if (parseJson.error) {
        return toast(parseJson.msg);
      }
    } catch (error) {
      toast("Check internet connection");
      console.log(error);
    }
  }, []);


  useEffect(() => {
    getInfo();
    getUserNotification();
    getPending()
  }, []);

  const deleteAllHandler = async () => {
    if (isDeleting) return;
    // get all IDS together!!!
    const IDS = data.map((v) => v._id);
    console.log("IDS HERE!! ====>> ", IDS);

    setIsDeleting(true);
    try {
      const fetching = await fetch(`${CTX.url}user/action/notification`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
        body: JSON.stringify({ ids: IDS }),
      });
      const jsoned = await fetching.json();
      setData(jsoned?.data);
      console.log("jsoned from getNoficiicaftion ==>> ", jsoned);
      setIsDeleting(false);
      toast("Deleted all notifications successfully!");
    } catch (error) {
      setIsDeleting(false);
      console.log("error from getNotification ==> ", error);
      if (error.response.status === 401) {
        return toast(error.response.data.msg);
      }
      toast("Check your internet connection");
    }
  };

  const deleteEachNot = async (id, e) => {
    if (loading) return;
    setLoading(true);
    try {
      const fetching = await fetch(`${CTX.url}user/action/notification/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${CTX.token}`,
        },
      });
      const jsoned = await fetching.json();
      setData(jsoned?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error from getNotification ==> ", error);
      if (error.response.status === 401) {
        return toast(error.response.data.msg);
      }
      toast("Check your internet connection");
    }
  };

  return (
    <Notification
      data={data}
      loading={loading}
      setLoading={setLoading}
      deleteAllHandler={deleteAllHandler}
      isDeleting={isDeleting}
      deleteEachNot={deleteEachNot}
    />
  );
};

export default EventNotifications;
