import React, {useState} from 'react';
import Layout from './components/layout/layout';
import {Toaster} from 'react-hot-toast';
import Login from './pages/login/login';
import TwoFALogin from './pages/2faLogin/2faLogin';
import Register from './pages/createAccount/create';
import {Navigate, Route, Routes} from 'react-router-dom';

function App() {
  // const url = 'http://localhost:4003/';
  const url = 'https://ruta-server.onrender.com/';
  const [token, setToken] = useState(sessionStorage.getItem('_tk') || '');
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem('user')) || {}
  );
  const [sidebar, setSidebar] = useState(false);

// logout handler section
  const logoutHandler = () => {
    sessionStorage.clear();
    setToken('');
    setUser({});
  };

  // setToken function
  const setTokenHandler = (_token) => {
    setToken(_token);
    // closeLogin();
    sessionStorage.setItem('_tk', _token);
  };

  // setUser function
  const setUserHandler = (_user) => {
    setUser(_user);
    sessionStorage.setItem('user', JSON.stringify(_user));
  };

  return (
    <MainContext.Provider
      value={{
        url,
        sideDrawer: sidebar,
        setSideDrawer: () => setSidebar(!sidebar),
        setToken: setTokenHandler,
        user,
        setUser: setUserHandler,
token,
        logout: logoutHandler,

      }}
    >

















<Routes>
      {!token && (
                <Route
                  path="/"
                  exact
                  element={<Navigate replace to={'/login'} />}
                />
              )}
              {token && (
                <Route
                  path="/login"
                  exact
                  element={<Navigate replace to={'/portfolio'} />}
                />
              )}
              {token && (
                <Route
                  path="/login/2fa"
                  exact
                  element={<Navigate replace to={'/portfolio'} />}
                />
              )}
               {token && (
                <Route
                  path="/register"
                  exact
                  element={<Navigate replace to={'/portfolio'} />}
                />
              )}
              {/* <Route path="/verify" exact element={<Verify />} />
              <Route path="/forgot-password" exact element={<EventForgot />} /> */}
              {/* <Route path="/reset-password/:token"  element={<EventReset />} /> */}

        {!token && <Route path="/login" element={<Login />} />}
        {!token && <Route path="/login/2fa" element={<TwoFALogin />} />}
        {!token && <Route path="/register" element={<Register />} />}
        {!token && (
                <Route
                  path="/*"
                  exact
                  element={<Navigate replace to={'/login'} />}
                />
              )}
      </Routes>


















      {token && <Layout />}

      <Toaster
        position="bottom-left"
        toastOptions={{
          style: {
            background: '#fc0',
            borderLeft: '5px solid #000',
            fontSize: '14px',
            color: '#000',
          },
        }}
      />
    </MainContext.Provider>
  );
}

export default App;

export const MainContext = React.createContext({
  url: null,
  sideDrawer: null,
  setSideDrawer: () => {},
  token: null,
  setToken: () => {},
  logout: () => {},
  setUser: () => {},
  user: null,

});





// Rewards history under the reward screen is to list all the people  that you have referred to ... it should list there names or email
// another thing is that "Your Level" under dashboard and reward screen should change to silver if the user reffres up to 50 users and gold if to 80users