import React from 'react'
import {ReactComponent as Products} from "../../assets/products.svg"
import {ReactComponent as Savings} from "../../assets/savings.svg"
import {ReactComponent as RevixLogo} from "../../assets/revix-logo.svg"
import {ReactComponent as Rewards} from "../../assets/rewards.svg"
import {ReactComponent as Profile} from "../../assets/profile.svg"
import { Link } from 'react-router-dom'
import Logo from '../../assets/logo.png'

const Footer = () => {
    return (
        <div className="block lg:hidden"><section id="bottom-navigation" className="block fixed inset-x-0 bottom-0 z-10 bg-background shadow-bottom-nav"><div id="tabs" className="flex justify-between">
            
            <Link to="/products/explore" className="text-body-text hover:text-header-text group w-full flex flex-col items-center text-center pt-2 pb-1 text-sm leading-5 font-medium focus:outline-none transition ease-in-out duration-150 bottom-nav-item">
            
            <Products className="h-6 w-6 mobile-step-3 false false transition ease-in-out duration-150 inline-block" fill="#575F9B" /><span className="false tab tab-home block text-xs">Products</span></Link>
            
            <Link to="/withdrawals" className="text-body-text hover:text-header-text group w-full flex flex-col items-center text-center pt-2 pb-1 text-sm leading-5 font-medium focus:outline-none transition ease-in-out duration-150 bottom-nav-item" >
                
                <Savings className="h-6 w-6 false mobile-step-4 false transition ease-in-out duration-150 inline-block" fill="#575F9B" /><span className="false tab tab-home block text-xs">Withdrawals</span></Link>
                <Link to="/portfolio" aria-current="page" className="text-header-text hover:text-header-text group w-full flex flex-col items-center text-center pt-2 pb-1 text-sm leading-5 font-medium focus:outline-none transition ease-in-out duration-150 bottom-nav-item bg-background" ><div className="bg-background rounded-full w-12 h-12 absolute bottom-5 z-0"></div>
                
                {/* <RevixLogo className="h-10 w-10 absolute bottom-6 mobile-step-1 false false false transition ease-in-out duration-150 inline-block" /> */}
                <img src={Logo} className="h-10 w-10 absolute bottom-6 mobile-step-1 false false false transition ease-in-out duration-150 inline-block" style={{height: "9px", marginBottom: "10px"}} />

                <span className="mt-6 tab tab-home block text-xs">Portfolio</span></Link>
                
                <Link className="text-body-text hover:text-header-text group w-full flex flex-col items-center text-center pt-2 pb-1 text-sm leading-5 font-medium focus:outline-none transition ease-in-out duration-150 bottom-nav-item" to="/rewards">
                    
                    <Rewards className="h-6 w-6 false false false transition ease-in-out duration-150 inline-block" fill="#575F9B" /><span className="false tab tab-home block text-xs">Rewards</span></Link>
                    
                    <Link className="text-body-text hover:text-header-text group w-full flex flex-col items-center text-center pt-2 pb-1 text-sm leading-5 font-medium focus:outline-none transition ease-in-out duration-150 bottom-nav-item" to="/profile">
                        
                        <Profile fill="#575F9B" className="h-6 w-6 false false mobile-step-8 transition ease-in-out duration-150 inline-block"  /><span className="false tab tab-home block text-xs">Profile</span></Link></div></section></div>
    )
}

export default Footer
