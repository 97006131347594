import React, { useContext } from "react";
import { MainContext } from "../../App";
import { ReactComponent as Menu } from "../../assets/imageHref/menu.svg";
import { ReactComponent as Notification } from "../../assets/notification.svg";
import { ReactComponent as Close } from "../../assets/closetwo.svg";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/button";
import Classes from "./classes.module.css";
import Suspense from "../../components/suspense/suspense";
import Loading from "../../components/loading/loading";
import { GiEmptyWoodBucket } from "react-icons/gi";

const Notifications = ({
  data,
  loading,
  deleteAllHandler,
  isDeleting,
  deleteEachNot,
}) => {
  const CTX = useContext(MainContext);
  const navigate = useNavigate();

  const mappedData = data.map((v, i) => (
    <div
      key={i}
      style={{
        display: "flex",
        // height: 100,
        borderBottom: "1px solid #fff",
        alignItems: "center",
        padding: "1em",
      }}
    >
      <Notification
        style={{ marginRight: ".8em", fill: "rgb(164, 167, 193)", width: 32 }}
      />
      <div>
      <span style={{ color: "#fff" }}>{v?.note}</span>
      <div style={{ color: "#ee", fontSize: '11px' }}>{v?.date}</div>
      </div>
      <div className={Classes.hello} onClick={deleteEachNot.bind(this, v._id)}>
        <Close
          fill="#fff"
          style={{
            fill: "#fff",
            width: "30px",
            height: "30px",
          }}
        />
      </div>
    </div>
  ));

  return (
    <div className="flex flex-col justify-center w-0 flex-1 overflow-hidden">
      <div className="xl:hidden pl-1 pt-1 pb-2 sm:pl-3 sm:pt-3 opacity-100 transition ease duration-1000 fixed z-10 h-12 lg:h-0 bg-body-background inset-x-0 top-0 lg:mr-4 lg:pt-0">
        <button
          type="button"
          className="absolute z-40 right-0 -ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-vivid-blue-500 focus:outline-none transition ease-in-out duration-150"
          aria-label="Open sidebar"
          onClick={CTX.setSideDrawer}
        >
          <Menu className="h-6 w-6" />
        </button>
      </div>
      <main
        id="main"
        className="overflow-y-auto flex-1 focus:outline-none pb-16 pt-10 lg:pb-0 lg:pt-0"
      >
        <div className="static lg:relative w-full">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="absolute lg:fixed mt-2 ml-6 text-2xl lg:mt-6 lg:ml-6 lg:text-base left-0 top-0 lg:top-auto lg:left-auto z-40 text-header-text focus:outline-none font-bold"
          >
            <span className="block lg:hidden">←</span>
            <span className="hidden lg:block py-4">← Back</span>
          </button>
          <div className="flex flex-row justify-start pt-10 -mb-4 sm:justify-center lg:mb-0 lg:pt-16 xl:pt-16">
            <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto">
                <div>
                  <div
                    className="flex  lg:px-8 sm:px-6 py-12"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "30px",
                    }}
                  >
                    <div></div>
                    <h2 className="font-bold leading-9 pl-11 pr-11 text-2.5x1  text-center">
                      Notifications
                    </h2>

                    {!loading && mappedData?.length > 0 && (
                      <div
                        onClick={deleteAllHandler}
                        style={{
                          padding: "5px 20px",
                          backgroundColor: "#1e2741",
                          borderRadius: "0.5rem",
                          cursor: "pointer",
                        }}
                      >
                        {isDeleting ? (
                          <Loading
                            fill="#888"
                            size="23px"
                            style={{ marginTop: "22px", marginLeft: "17px" }}
                          />
                        ) : (
                          <span>Delete all</span>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="py-5 flex flex-col">
                    {loading ? (
                      <div style={{ marginTop: "30px" }}>
                        <Suspense />
                      </div>
                    ) : (
                      <>
                        {mappedData?.length > 0 ? (
                          mappedData
                        ) : (
                          <div>

                            <div style={{alignItems: "center"}} className="bg-background mb-1 rounded-lg h-auto min-h-20 py-3 px-2 w-full flex flex-col justify-center text-center">
                            <GiEmptyWoodBucket color="#bbb" size={58} style={{marginBottom: "12px"}} />
                              No records found
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="hidden pointer-events-none   fixed h-screen overflow-y-auto inset-x-0 bottom-0 flex items-start px-4 pb-6 justify-start md:h-auto md:inset-0 md:p-0 md:items-center md:flex md:justify-center lg:h-auto lg:inset-0 lg:p-0 lg:flex lg:items-center lg:justify-center xl:h-auto xl:inset-0 xl:p-0 xl:flex xl:items-center xl:justify-center z-50"></div>
              <div className="hidden pointer-events-none   fixed h-screen overflow-y-auto inset-x-0 bottom-0 flex items-start px-4 pb-6 justify-start md:h-auto md:inset-0 md:p-0 md:items-center md:flex md:justify-center lg:h-auto lg:inset-0 lg:p-0 lg:flex lg:items-center lg:justify-center xl:h-auto xl:inset-0 xl:p-0 xl:flex xl:items-center xl:justify-center z-50"></div>
              <div className="hidden pointer-events-none   fixed h-screen overflow-y-auto inset-x-0 bottom-0 flex items-start px-4 pb-6 justify-start md:h-auto md:inset-0 md:p-0 md:items-center md:flex md:justify-center lg:h-auto lg:inset-0 lg:p-0 lg:flex lg:items-center lg:justify-center xl:h-auto xl:inset-0 xl:p-0 xl:flex xl:items-center xl:justify-center z-50"></div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Notifications;
