import React, {lazy, Suspense, useContext} from 'react';
import Sidebar from '../sidebar/sidebar';
import Loading from '../suspense/suspense'
import Footer from '../footer/footer';
import SideDrawer from '../sidedrawer/sidedrawer';
import {MainContext} from '../../App';
import {Navigate, Route, Routes} from 'react-router-dom';
// import Contact from "../../pages/contact/contact"
import EventNotifications from '../../pages/notifications/eventNotifications';
const Product = lazy(() => import('../../pages/products/eventProduct'));
const Transaction = lazy(() => import('../../pages/transaction/transaction'));
const Rewards = lazy(() => import('../../pages/rewards/eventRewards'));
const Profile = lazy(() => import('../../pages/profile/eventProfile'));
const Password = lazy(() => import('../../pages/profile/password'));
const Address = lazy(() => import('../../pages/profile/address'));
const Bank = lazy(() => import('../../pages/profile/bank'));
const TwoFA = lazy(() => import('../../pages/profile/2fa'));
const Email = lazy(() => import('../../pages/profile/email'));
const Login = lazy(() => import('../../pages/login/login'));
const Verify = lazy(() => import('../../pages/verify/verify'));
const Intent = lazy(() => import('../../pages/verify/intent'));
const Employment = lazy(() => import('../../pages/verify/employment'));
const Source = lazy(() => import('../../pages/verify/source'));
const Residence = lazy(() => import('../../pages/verify/residence'));
const Home = lazy(() => import('../../pages/home/eventHome'));
const CreateAccount = lazy(() => import('../../pages/createAccount/create'));
const EachProduct = lazy(() => import('../../pages/eachProduct/eachProduct'));
const Payments = lazy(() => import('../../pages/verify/payment'));
const TopUp = lazy(() => import('../../pages/profile/topup/eventTopUp'));
const Withdrawal = lazy(() => import('../../pages/withdrawal/eventWithdrawal'));

const Layout = () => {
  const CTX = useContext(MainContext);

  return (
    <div className="h-full w-full">
      <div
        style={{height: '100%'}}
        className="flex bg-body-background overflow-hidden"
      >
        {CTX.sideDrawer && <SideDrawer />}
        <Sidebar />
        <Routes>
        {CTX.token && (
                <Route
                  path="/auth"
                  exact
                  element={<Navigate replace to={'/portfolio'} />}
                />
              )}
        {CTX.token && (
          <Route
          path="/"
          exact
          element={<Navigate replace to={'/portfolio'} />}
          />
          )}
          
          {!CTX.token && (
                          <Route
                            path="/"
                            exact
                            element={<Navigate replace to={'/auth'} />}
                          />
                        )}


        {!CTX.token && <Route path="/auth" element={<Suspense fallback={<Loading />}><Login /></Suspense>} />}
        {!CTX.token && <Route path="/register" element={<Suspense fallback={<Loading />}><CreateAccount /></Suspense>} />}
        {CTX.token && <Route path="/portfolio" element={<Suspense fallback={<Loading />}><Home /></Suspense>} />}
        {CTX.token && <Route path="/products/explore" element={<Suspense fallback={<Loading />}><Product /></Suspense>} />}
        {CTX.token && <Route path="/products/explore/:ID" element={<Suspense fallback={<Loading />}><EachProduct /></Suspense>} />}
        {CTX.token && <Route path="/rewards" element={<Suspense fallback={<Loading />}><Rewards /></Suspense>} />}
        {CTX.token && <Route path="/profile" exact element={<Suspense fallback={<Loading />}><Profile /></Suspense>} />}
        {CTX.token && <Route path="/profile/password" exact element={<Suspense fallback={<Loading />}><Password /></Suspense>} />}
        {CTX.token && <Route path="/profile/referral" exact element={<Suspense fallback={<Loading />}><Address /></Suspense>} />}
        {CTX.token && <Route path="/profile/bank" exact element={<Suspense fallback={<Loading />}><Bank /></Suspense>} />}
        {CTX.token && <Route path="/profile/email" exact element={<Suspense fallback={<Loading />}><Email /></Suspense>} />}
        {CTX.token && <Route path="/profile/2fa" exact element={<Suspense fallback={<Loading />}><TwoFA /></Suspense>} />}
        {CTX.token && <Route path="/transactions" exact element={<Suspense fallback={<Loading />}><Transaction /></Suspense>} />}
        {CTX.token && <Route path="/profile/verification/what-you-will-need" exact element={<Suspense fallback={<Loading />}><Verify /></Suspense>} />}
        {CTX.token && <Route path="/profile/verification/intent" exact element={<Suspense fallback={<Loading />}><Intent /></Suspense>} />}
        {CTX.token && <Route path="/profile/verification/employment" exact element={<Suspense fallback={<Loading />}><Employment /></Suspense>} />}
        {CTX.token && <Route path="/profile/verification/source-of-funds" exact element={<Suspense fallback={<Loading />}><Source /></Suspense>} />}
        {CTX.token && <Route path="/profile/verification/form" exact element={<Suspense fallback={<Loading />}><Residence /></Suspense>} />}
        {CTX.token && <Route path="/profile/verification/payment" exact element={<Suspense fallback={<Loading />}><Payments /></Suspense>} />}
        {CTX.token && <Route path="/profile/top-up" exact element={<Suspense fallback={<Loading />}><TopUp /></Suspense>} />}
        {CTX.token && <Route path="/withdrawals" exact element={<Suspense fallback={<Loading />}><Withdrawal /></Suspense>} />}
        {CTX.token && <Route path="/account/notifications" exact element={<Suspense fallback={<Loading />}><EventNotifications /></Suspense>} />}
{/* {CTX.token && (
                <Route
                  path="/*"
                  exact
                  element={<Navigate replace to={'/portfolio'} />}
                />
              )} */}
              {!CTX.token && (
                <Route
                  path="/*"
                  exact
                  element={<Navigate replace to={'/auth'} />}
                />
              )}
        {/* {!CTX.token && <Route path="/*" element={<Login />} />} */}
        </Routes>

        <Footer />
      </div>
    </div>
  );
};

export default Layout;
