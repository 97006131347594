import React from 'react';
import Classes from './button.module.css';
import Loading from '../../components/loading/loading';

const Button = ({style, disabled, loading, text, onClick}) => {
  return (
    <button style={style} disabled={disabled || loading} onClick={onClick} className={Classes.signOnBTN}>
      {loading ? (
        <Loading
          fill="#888"
          size="23px"
          style={{marginTop: '22px', marginLeft: '17px'}}
        />
      ) : (
        <span>{text}</span>
      )}
    </button>
  );
};

export default Button;
