import React, { useContext, useState } from "react";
import Classes from "./sidebar.module.css";
import { NavLink, Link } from "react-router-dom";
import { ReactComponent as Profile } from "../../assets/profile.svg";
import LogoWhite from "../../assets/logo.png";
import { ReactComponent as BlueSide } from "../../assets/blue-side-star.svg";
import Silver from "../../assets/silver.png";
import Gold from "../../assets/gold.png";
import { ReactComponent as PortfolioActive } from "../../assets/portfolio-active.svg";
import { ReactComponent as Products } from "../../assets/products.svg";
import { ReactComponent as Rewards } from "../../assets/rewards.svg";
import { ReactComponent as Transactions } from "../../assets/transactions.svg";
import { ReactComponent as Notification } from "../../assets/notification.svg";
import { ReactComponent as Resources } from "../../assets/resources.svg";
import { ReactComponent as Logout } from "../../assets/logout.svg";
import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import { ReactComponent as Linkedin } from "../../assets/linkedin.svg";
import { ReactComponent as Twitter } from "../../assets/twitter.svg";
import { ReactComponent as Savings } from "../../assets/savings.svg";
import { MainContext } from "../../App";

const Sidebar = () => {
  const [toggleRes, setToggleRes] = useState(false);
  const CTX = useContext(MainContext);
  return (
    <div className="hidden xl:flex">
      <div className="flex flex-col w-64 bg-background">
        <div className="h-0 flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center justify-center flex-shrink-0 px-4">
            <Link className="active" to="/portfolio">
              <img
                style={{ width: "200px" }}
                src={require("../../assets/logo.png")}
                alt="logo"
              />
            </Link>
          </div>
          <div
            style={{ marginTop: "-8px" }}
            className="flex flex-col items-center justify-center px-4 pt-8"
          >
            <div className="text-header-text">{CTX?.user?.full_name}</div>
            <div className="text-body-text pt-2 font-bold">
              {CTX?.user?.referral?.length < 50
                ? "Blue"
                : CTX?.user?.referral?.length > 50 &&
                  CTX?.user?.referral?.length < 70
                ? "Silver"
                : "Gold"}
            </div>
            <div className="flex flex-row pt-1">
              {CTX?.user?.referral?.length < 50 ? (
                <BlueSide />
              ) : CTX?.user?.referral?.length > 50 &&
                CTX?.user?.referral?.length < 70 ? (
                <img src={Silver} alt=" " style={{ width: "15.87px" }} />
              ) : (
                <img src={Gold} alt=" " style={{ width: "15.87px" }} />
              )}
            </div>
          </div>
          <nav className={Classes.navCover}>
            <NavLink
              aria-current="page"
              className={Classes.navLinkCover}
              to="/portfolio"
            >
              <PortfolioActive />
              Portfolio
            </NavLink>
            <NavLink className={Classes.navLinkCover} to="/products/explore">
              <Products />
              Products
            </NavLink>

            <NavLink className={Classes.navLinkCover} to="/withdrawals">
              <Savings />
              Withdrawal
            </NavLink>

            <NavLink className={Classes.navLinkCover} to="/rewards">
              <Rewards />
              Rewards
            </NavLink>
            <NavLink className={Classes.navLinkCover} to="/profile">
              <Profile />
              Profile
            </NavLink>
            <NavLink className={Classes.navLinkCover} to="/transactions?tab=pending">
              <Transactions />
              Transactions
            </NavLink>

            <NavLink
              className={Classes.navLinkCover}
              to="/account/notifications"
            >
              <Notification style={{width: '25px'}} />
              Notifications
            </NavLink>

            <div
              onClick={() => setToggleRes(!toggleRes)}
              className={Classes.navLinkCover}
            >
              <Resources />
              Resources
            </div>

            {toggleRes && (
              <>
                <a
                  target="_blank"
                  href="https://www.mtnonline.com/personal/4g/"
                >
                  <button
                    type="button"
                    style={{ marginTop: "6px" }}
                    className="text-body-text z-40 mt-4 group w-full py-2 flex items-center justify-center text-center text-sm text-vivid-blue-50 hover:text-header-text hover:bg-secondary leading-5 font-bold focus:outline-none transition ease-in-out duration-150"
                  >
                    - 4G LTE
                  </button>
                </a>

                <a target="_blank" href="https://www.mtnonline.com/broadband">
                  <button
                    type="button"
                    style={{ marginTop: "6px" }}
                    className="text-body-text z-40 mt-4 group w-full py-2 flex items-center justify-center text-center text-sm text-vivid-blue-50 hover:text-header-text hover:bg-secondary leading-5 font-bold focus:outline-none transition ease-in-out duration-150"
                  >
                    - Broadband
                  </button>
                </a>

                <a
                  target="_blank"
                  href="https://www.mtnonline.com/personal/data/data-plans"
                >
                  <button
                    type="button"
                    style={{ marginTop: "6px" }}
                    className="text-body-text z-40 mt-4 group w-full py-2 flex items-center justify-center text-center text-sm text-vivid-blue-50 hover:text-header-text hover:bg-secondary leading-5 font-bold focus:outline-none transition ease-in-out duration-150"
                  >
                    - Data Plans
                  </button>
                </a>
              </>
            )}
          </nav>
        </div>
        <div className="pb-4">
          <button
            onClick={() => CTX.logout()}
            type="button"
            style={{ paddingTop: "20px" }}
            className="w-full flex items-center pr-2 pl-8 py-2 hover:bg-secondary text-sm leading-5 font-bold text-body-text hover:text-header-text focus:outline-none focus:text-body-text focus:bg-secondary transition ease-in-out duration-150"
          >
            <Logout />
            Log out
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
