import React, {useContext, useRef, useState} from 'react';
import Classes from './input.module.css';
import {ReactComponent as Caution} from '../../assets/caution.svg';

const Input = ({inputs, setInputs, label, htmlFor, require, style, sideText, type="text", readOnly}) => {
  const [userErr, setUserErr] = useState(false);
  const [laebl, setLaebl] = useState(false);

  const onChangeHandler = (name, e) => {
    // setLaebl(true)
    const spread = {...inputs};
    spread[name] = e.target.value;
    setInputs(spread);
    if (spread[name].length < 1) {
      setLaebl(false);
      setUserErr(true);
    } else {
      setLaebl(true);
      setUserErr(false);
    }
  };

  return (
    <div className={Classes.eachMainInputCover}>
      <label
        htmlFor={htmlFor}
        style={{
          color: userErr && require  ? '#b42d19' : 'rgba(109, 114, 139, 1)',
          fontSize: '16px',
          lineHeight: laebl ? '0.8125rem' : '1.4375rem',
          display: 'flex',
          margin: laebl ? '8px 0 -8px 8px' : '32px 0 -32px 8px',
        }}
        className={Classes.onlyBorderLabel}
      >
        {userErr && require ? (
          <Caution fill="#b42d19" style={{width: '13px', marginRight: '2px'}} />
        ) : (
          <>{require && '*'} </>
        )}{' '}
        {label}
      </label>
      <input  type="hidden"  />
      {!userErr && <span style={{display: laebl  ?  "block":  "none",  color: userErr && require  ? '#b42d19' : 'rgba(109, 114, 139, 1)'}} className={Classes.sideTextHere}>{sideText}</span>}
      <input
        autoComplete='none'
        placeholder=" "
        readOnly={readOnly}
        style={style}
        required={require}
        type={type}
        onClick={() => setLaebl(true)}
        onChange={onChangeHandler.bind(this, htmlFor)}
        name={htmlFor}
        value={inputs?.htmlFor}
        className={Classes.onlyBorderInput}
      />
    </div>
  );
};

export default Input;

